import React, { FC } from 'react'
import css from './styles.module.css'
import { motion } from 'framer-motion'
import { useAnimationTrigger } from 'hooks'

export type degreeT = 'engineer' | 'blockchain'

const degreesMock: Record<
  degreeT,
  {
    title: string
    duration: string
    location: string
  }
> = {
  engineer: {
    title: 'Systems Engineer',
    location: 'I.U Politécnico Santiago Mariño',
    duration: '2014 - 2019',
  },
  blockchain: {
    title: 'Blockchain Developer',
    location: 'Kingsland Blockchain University',
    duration: 'April 2019 - December 2019',
  },
}

const DegreeCard: FC<{
  degreeType: degreeT
  isVisible: boolean
}> = ({ degreeType, isVisible }) => {
  const { title, duration, location } = degreesMock[degreeType]
  const controls = useAnimationTrigger(isVisible, {
    opacity: 1,
    x: 0,
    transition: {
      duration: 0.5,
      delay: 0.2,
    },
  })

  const openDoc = () => {
    if (degreeType === 'blockchain') window.open('/docs/blockchain-certificate.pdf', '_blank')
  }

  return (
    <motion.div
      key={degreeType}
      className={`${css.container} ${css[`${degreeType}_gr`]}`}
      initial={{
        opacity: 0,
        x: 600,
      }}
      animate={controls}
      whileHover={
        degreeType === 'blockchain'
          ? {
              scale: 0.95,
              cursor: 'pointer',
              transition: {
                duration: 0.3,
              },
            }
          : {}
      }
      onClick={openDoc}
    >
      <h3>{title}</h3>
      <div className={css.description}>
        <p>{location}</p>
        <p>{duration}</p>
      </div>
    </motion.div>
  )
}

export default DegreeCard
