import React from 'react'
import css from './articles.module.css'
import articlesMock, { ArticleCardI } from './articles'
import PortfolioCard from 'components/PortfolioCard'

const ArticlesSection = () => {
  return (
    <div className={css.wrapper}>
      <h1 className={css.portfolio}>Articles by Me:</h1>
      <div className={css.cardsContainer}>
        <div className={css.gridColumn}>
          {articlesMock
            .filter(({ column }: ArticleCardI) => column === 1)
            .map((card) => (
              <PortfolioCard mode="article" {...card} key={card.title} />
            ))}
        </div>
        <div className={css.gridColumn}>
          {articlesMock
            .filter(({ column }: ArticleCardI) => column === 2)
            .map((card) => (
              <PortfolioCard mode="article" {...card} key={card.title} />
            ))}
        </div>
        <div className={css.gridColumn}>
          {articlesMock
            .filter(({ column }: ArticleCardI) => column === 3)
            .map((card) => (
              <PortfolioCard mode="article" {...card} key={card.title} />
            ))}
        </div>
      </div>
    </div>
  )
}

export default ArticlesSection
