import React, { FC } from 'react'
import css from './stacklist.module.css'
import { motion } from 'framer-motion'
import { useAnimationTrigger } from 'hooks'

export type TStackName = 'front' | 'back' | 'soft'

const stackList: Record<TStackName, { list: string[]; title: string }> = {
  front: {
    title: 'Frontend & Mobile Stack',
    list: [
      'React js',
      'React Native Expo',
      'React Native CLI',
      'Material UI',
      'React Query',
      'Chakra Design',
      'Redux',
      'Ant Design',
    ],
  },
  back: {
    title: 'Backend Stack',
    list: [
      'Express Js',
      'Node Js',
      'Lumen',
      'Laravel',
      'MySQL',
      'Maria DB',
      'Postgres',
      'Sequalize',
    ],
  },
  soft: {
    title: 'Other Skills',
    list: ['Scrum', 'Kanban', 'Gitlab', 'Github', 'Bitbucket', 'Jira', 'Figma', 'GIT'],
  },
}

const LanguageStacks: FC<{
  isVisible: boolean
  stack: TStackName
}> = ({ stack, isVisible }) => {
  const controls = useAnimationTrigger(isVisible, {
    opacity: 1,
    transition: {
      duration: 1,
      delay: 0.2,
    },
  })

  const { list, title } = stackList[stack]

  return (
    <motion.div
      className={`${css.stackListContainer} ${css[`${stack}-position`]}`}
      initial={{
        opacity: 0,
      }}
      animate={controls}
    >
      <h3 className={css.title}>{title}</h3>
      <div className={css.listContainer}>
        {list.map((tech) => (
          <div className={`${css.techContainer} ${css[`${stack}-color`]}`}>
            <span className={css.indicator} />
            <p>{tech}</p>
          </div>
        ))}
      </div>
    </motion.div>
  )
}

export default LanguageStacks
