import beachTen from 'assets/images/beach-tennis-card-banner.png'
import wod from 'assets/images/wod-card-banner.png'
import fibe from 'assets/images/fibe-card-banner.png'
import catalog from 'assets/images/canal-digital-card-banner.png'
import ife from 'assets/images/ife-card-banner.png'
import shoppingCart from 'assets/images/shopping-cart-card-banner.png'
import keironChallenge from 'assets/images/keiron-challenge-card-banner.png'
import juntos from 'assets/images/juntos-de-nuevo-card-banner.png'
import oemia from 'assets/images/oemia-card-banner.png'
import ventatoday from 'assets/images/ventatoday-card-banne.png'
import guick from 'assets/images/guick-card-banner.png'
import glewee from 'assets/images/glewee-card-banner.png'
import { companiesT } from './hooks'

export interface PortfolioCardI {
  title: string
  link?: string
  description: string[]
  sign: companiesT
  charge:
    | 'One Man Work'
    | 'LEAD DEVELOPER'
    | 'FULL STACK DEVELOPER'
    | 'Email Developer'
    | 'Software Engineer'
  image: string
  status?: 'Side project' | 'Finished' | 'Canceled development' | ''
  productionStatus?:
    | 'Live Platform'
    | 'in development'
    | 'partially in development'
    | 'Production live'
    | 'No live'
  column: 1 | 2 | 3
}
const portfolioMock: PortfolioCardI[] = [
  {
    image: glewee,
    productionStatus: 'Live Platform',
    title: 'Glewee',
    description: [
      'By so far, the biggest and more exciting project I’ve being part of. Glewee it’s a social marketplace built to connect the best Content Creators with Brands that want’s to generate advertacing with them.',
      'I was part of the original MPV for the Beta launch and then, part of the maintenience and development team once the MPV made it’s work. I had several positions around the organization, like Mobile Developer for the Android and iOS app, Scrum Master for the MVP team, Tech Leader and finally, Frontend Engineer in the Brand Squad.',
      'Glewee members were like family for me and it’s my best example of Remote work/passion power!',
    ],
    sign: 'glewee',
    link: 'https://www.glewee.com',
    charge: 'Software Engineer',
    column: 1,
  },
  {
    image: beachTen,
    status: 'Side project',
    productionStatus: 'in development',
    title: 'The Beach Tennis App',
    description: [
      'It’s an app to create and have an easy to use scoreboard for the Beach Tennis fans. Initially, it was planned as a WPA but, following the concept of “easy to use”, now it’s being develop as an mobile app with React Native and Django.',
      'UI and development was a one man work and its my first side project that it’s being published as an official app in the store, hopefully will be available soon.',
    ],
    link: 'https://github.com/Beach-Tennis',
    charge: 'One Man Work',
    column: 2,
    sign: 'personal',
  },
  {
    image: wod,
    status: 'Side project',
    productionStatus: 'partially in development',
    title: 'WOD',
    description: [
      "App to control and management of the work time and tasks with the Pomodoro tecnique, has an task manager, cronos, a time management system and activities records. Is an app which is currently on construction of basics business notions. It's a personal project I hope to realize on near future.",
    ],
    charge: 'One Man Work',
    column: 3,
    sign: 'personal',
  },
  {
    image: fibe,
    status: 'Finished',
    productionStatus: 'Production live',
    title: 'FIBE',
    description: [
      'The project made for the Ministery of Social Development in Chile to the digitalization of emergency tickets issued on risk situations on Chilen territory, Has a slack of Lumen for its backend and ReactJs for its Frontend.',
      "I was part of the three developers team, being the only FullStack of the team, I had the job of helping where I was needed and elaborate complete modules as the documents management module. Maybe when clicking the link the site didn't load properly, this is because the Ministery of Social Development has a Chilens IPs limitation",
    ],
    link: 'http://fibe.ministeriodesarrollosocial.gob.cl',
    sign: 'keiron',
    charge: 'FULL STACK DEVELOPER',
    column: 2,
  },
  {
    image: catalog,
    status: 'Canceled development',
    productionStatus: 'No live',
    title: 'Digital Catalog of Experiences',
    description: [
      "Project to the National Secretary of Tourism in Chile (SERNATUR), it's about a platform to the service providers of touristic experiences in Chile, to publish their services on the Ministery of Tourism pages www.chileestuyo.cl and www.chile.travel as a WordPress Widget to all the people interested on Chilean tourism. The app has a slack of NodeJs with ExpressJs and Sequelize for its backend, also a ReactJs for the frontend and the Widget.",
      'I was part of the three developers team, performing as the lead developer and also sharing functions to the project management under Scrum Methodology and sharing business pieces of stuff with the Product Owner of the system',
    ],
    sign: 'keiron',
    charge: 'LEAD DEVELOPER',
    column: 1,
  },
  {
    image: ife,
    status: 'Finished',
    productionStatus: 'Production live',
    title: 'IFE',
    description: [
      "A project developed to the Ministery of Social Development in Chile to the Emergency Income as the contingency of COVID-19 to the Chilean citizens has a slack of Lumen for its backend and ReactJs to its front. It was hosted on AWS in the development environment. It was Developed using GitFlow and Scrum in its organization. I was part of the two developers' team.",
      "Maybe when clicking the link the site didn't load properly, this is because the Ministery of Social Development has a Chilens IPs limitation.",
    ],
    link: 'https://www.ingresodeemergencia.cl',
    sign: 'keiron',
    charge: 'FULL STACK DEVELOPER',
    column: 3,
  },
  {
    image: shoppingCart,
    status: 'Finished',
    productionStatus: 'No live',
    title: 'Shopping Cart Frontend',
    description: [
      'A project made for a job offer as a challenge. Made with React Hooks, Redux & Axios. Has an Object-Oriented Axios controller. The project is directly dependent on an API to work. Counts with features like product lists, shopping cart, and a payment gateway.',
    ],
    link: 'https://gitlab.com/carlosalfonzo/shopping-cart-frontend',
    charge: 'One Man Work',
    column: 2,
    sign: 'personal',
  },
  {
    image: shoppingCart,
    status: 'Finished',
    productionStatus: 'No live',
    title: 'Shopping Cart API',
    description: [
      "API restful project for a job offer, made with plain PHP as a requirement. The approach to this project was getting a nearing Laravel Frameork's workflow but made from scratch. Has a MySql database connection. It uses only a few dependencies to work. It connects to a React App to present a friendly GUI. You can find the React project on this same portfolio as Shopping Cart Frontend.",
    ],
    link: 'https://gitlab.com/carlosalfonzo/shopping-cart-api',
    charge: 'One Man Work',
    column: 3,
    sign: 'personal',
  },
  {
    image: juntos,
    status: 'Finished',
    productionStatus: 'Production live',
    title: 'Togethers All Again',
    description: [
      "Charity project to the Chilean market to the capacity control on Locals and public places, the app keeps a virtual line and notifies to the persons when they're about to get their turn to go into the place, also each location has a QR code to scan on a place you're going to visit.",
      'The app has a Slack of NodeJs with ExpressJs and Sequelize to the backend, also a ReactJs app to the frontend.',
      'I was part of the development team among more than 6 developers who offered free labor hours to complete the app.',
    ],
    link: 'https://juntosdenuevo.cl',
    sign: 'keiron',
    charge: 'FULL STACK DEVELOPER',
    column: 1,
  },
  {
    image: keironChallenge,
    status: 'Finished',
    productionStatus: 'No live',
    title: 'Keiron Challenge Project',
    description: [
      'This project made for a Job offer is an App with an API made with Laravel Framework, with a React App to be connected, and offers a GUI. The project is about an App with two user roles who interact with each other to request Tickets. The admin can assign tickets to users and, these can claim one.',
    ],
    link: 'https://gitlab.com/carlosalfonzo/keiron-challenge-project',
    charge: 'One Man Work',
    column: 1,
    sign: 'personal',
  },
  {
    image: oemia,
    status: 'Finished',
    productionStatus: 'Live Platform',
    title: 'Oemia',
    description: [
      "This is a lawyer buffet app for the Spain market. Has a stack of Laravel and React. Counts with many features as third API's integration like Gmail, an file and folder storage system, a contacts management system, expedients, users, headquarters, and analytics. It was my last app I made for this sign.",
      'in this project, I was the lead developer for a 4 developing team. Also, I made Project Management tasks before starting the project, writing down the Systems Requirements, and contributing to the business model design, I also implement a Scrum methodology for the developing stage.',
    ],
    link: 'https://development.oemia.es/',
    sign: 'oxoft',
    charge: 'LEAD DEVELOPER',
    column: 2,
  },
  {
    image: ventatoday,
    status: 'Finished',
    productionStatus: 'No live',
    title: 'VentaToday',
    description: [
      'Department Selling App for the Venezuelan market, inspired by Mercadolibre and Amazon for its business structure. Has a search engine, departments and categories systems, reputations and qualifying systems, also an ordering algorithm reputation based, a banners systems, and an intern analytics engine.',
      'I developed almost 80% of this project on my own, I was the lead developer for this project, also giving business structure concepts.',
    ],
    link: 'https://ventatoday.com',
    sign: 'oxoft',
    charge: 'LEAD DEVELOPER',
    column: 3,
  },
  {
    image: guick,
    status: 'Finished',
    productionStatus: 'Production live',
    title: 'Guick',
    description: [
      "Delivery App for Fastfood, inspired with Globo and Uber Eats for its business structure within Lecheria, Anzoategui, Venezuela. The app stack is Laravel, NodeJs, and a React App. I was part of the development team of this project, I made the views and working on Laravel API's mails, made with Blade.",
    ],
    link: 'https://www.guick.com',
    sign: 'oxoft',
    charge: 'Email Developer',
    column: 1,
  },
]

export default portfolioMock
