import React, { CSSProperties, FC } from 'react'
import css from './styles.module.css'
import oxoftLogo from 'assets/images/oxoft-logo.png'
import oxoftBg from 'assets/images/oxoft-background.png'
import lenioLogo from 'assets/images/lenio-logo.png'
import lenioBg from 'assets/images/lenio-background.png'
import keironLogo from 'assets/images/keiron-logo.png'
import keironBg from 'assets/images/keiron-background.png'
import galindoLogo from 'assets/images/galindo-logo.png'
import galindoBg from 'assets/images/galindo-background.png'
import gleweeLogo from 'assets/images/glewee-logo.png'
import gleweeBg from 'assets/images/glewee-background.png'
import { motion } from 'framer-motion'
import { useAnimationTrigger } from 'hooks'

export type JobT = 'keiron' | 'galindo' | 'oxoft' | 'glewee' | 'lenio'

const jobsMock: Record<
  JobT,
  {
    title: string
    role: string
    location: string
    duration: string
    logo: string
    background: string
    orientation?: 'row'
    size?: number
    logoAlignment?: 'flex-start'
    titleSize?: '2em' | '2.5em'
  }
> = {
  oxoft: {
    title: 'Oxoft Group',
    role: 'Software Developer',
    location: 'on-site - Lecheria, Venezuela',
    duration: 'Jul. 2018 - Dec. 2019',
    logo: oxoftLogo,
    background: oxoftBg,
    size: 110,
  },
  keiron: {
    title: 'Keirón',
    role: 'Full Stack Developer',
    location: 'Remote - Santiago de Chile, Chile',
    duration: 'Apr. 2020 - Oct. 2020',
    logo: keironLogo,
    background: keironBg,
    size: 50,
  },
  galindo: {
    title: 'The Galindo Consulting Group',
    role: 'Full Stack Engineer & Scrum Master',
    location: 'Remote - USA',
    duration: 'Dec. 2020 - Mar. 2021',
    logo: galindoLogo,
    background: galindoBg,
    orientation: 'row',
    logoAlignment: 'flex-start',
    titleSize: '2em',
    size: 95,
  },
  glewee: {
    title: 'Glewee',
    role: 'Mobile & Web Frontend Engineer',
    location: 'Remote - Tampa, USA',
    duration: 'Apr. 2021 - Mar. 2023',
    logo: gleweeLogo,
    background: gleweeBg,
    size: 70,
    logoAlignment: 'flex-start',
    titleSize: '2.5em',
  },
  lenio: {
    title: 'Leniolabs',
    role: 'Full-Stack Software Engineer',
    location: 'Remote - Buenos Aires, ARG',
    duration: 'Oct. 2023 - Today',
    logo: lenioLogo,
    background: lenioBg,
    size: 70,
    logoAlignment: 'flex-start',
    titleSize: '2.5em',
  },
}

const JobCard: FC<{
  jobTitle: JobT
  isVisible: boolean
  style?: CSSProperties
}> = ({ jobTitle, style, isVisible }) => {
  const controls = useAnimationTrigger(isVisible, {
    // transform: 'scale(1)',
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.5,
      delay: 0.2,
    },
  })

  const {
    title,
    role,
    location,
    duration,
    logo,
    background,
    size,
    orientation,
    logoAlignment,
    titleSize,
  } = jobsMock[jobTitle]

  const openLink = () => {
    window.open('https://www.linkedin.com/in/carlos-alfonzo/details/experience/', '_blank')
  }

  return (
    <motion.div
      initial={{
        opacity: 0,
        y: 1000,
      }}
      animate={controls}
      style={{
        width: '100%',
        height: '100%',
        ...(style || {}),
      }}
      whileHover={{
        cursor: 'pointer',
        scale: 0.9,
      }}
      onClick={openLink}
    >
      <div className={`${css.container} ${css[`${jobTitle}_gr`]}`}>
        <div className={css.content}>
          <div
            className={css.titleAndLogo}
            style={{
              alignItems: logoAlignment || 'inherit',
            }}
          >
            <h4
              style={{
                fontSize: titleSize || '2.5em',
              }}
            >
              {title}
            </h4>
            <div
              className={css.logoContainer}
              style={{
                width: size || 'unset',
              }}
            >
              <img src={logo} alt={`${jobTitle}-logo`} />
            </div>
          </div>
          <h5>{role}</h5>
          <div
            className={css.description}
            style={{
              flexDirection: orientation || 'inherit',
              justifyContent: orientation ? 'space-between' : 'unset',
            }}
          >
            <p>{location}</p>
            <p>{duration}</p>
          </div>
        </div>
        <div
          style={{
            backgroundImage: `url(${background})`,
          }}
          className={css.bgImage}
        />
      </div>
    </motion.div>
  )
}

export default JobCard
