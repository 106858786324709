import React, { FC } from 'react'
import css from './languageCard.module.css'
import jsLogo from 'assets/images/javascript-logo.png'
import jsBg from 'assets/images/javascript-background.png'
import tsLogo from 'assets/images/typescript-logo.png'
import tsBg from 'assets/images/typescript-background.png'
import pyLogo from 'assets/images/python-logo.png'
import pyBg from 'assets/images/python-background.png'
import phpLogo from 'assets/images/php-logo.png'
import phpBg from 'assets/images/php-background.png'
import sqlLogo from 'assets/images/sql-logo.png'
import sqlBg from 'assets/images/sql-background.png'
import solLogo from 'assets/images/solidity-logo.png'
import solBg from 'assets/images/solidity-background.png'
import { motion } from 'framer-motion'
import { useAnimationTrigger } from 'hooks'

export type TLanguageName = 'js' | 'ts' | 'py' | 'php' | 'sql' | 'sol'

const languageCardMock: Record<
  TLanguageName,
  {
    title: string
    logo: string
    background: string
    delay: number
  }
> = {
  js: {
    title: 'Javascript',
    logo: jsLogo,
    background: jsBg,
    delay: 0.0,
  },
  ts: {
    title: 'TypeScript',
    logo: tsLogo,
    background: tsBg,
    delay: 0.2,
  },
  py: {
    title: 'Python',
    logo: pyLogo,
    background: pyBg,
    delay: 0.4,
  },
  php: {
    title: 'PHP',
    logo: phpLogo,
    background: phpBg,
    delay: 0.6,
  },
  sql: {
    title: 'SQL',
    logo: sqlLogo,
    background: sqlBg,
    delay: 0.8,
  },
  sol: {
    title: 'Solidity',
    logo: solLogo,
    background: solBg,
    delay: 1.0,
  },
}

const LanguageCard: FC<{ language: TLanguageName; isVisible: boolean }> = ({
  language,
  isVisible,
}) => {
  const { title, logo, background, delay } = languageCardMock[language]
  const controls = useAnimationTrigger(isVisible, {
    x: 0,
    opacity: 1,
    transition: {
      duration: 0.5,
      delay: delay,
    },
  })

  return (
    <motion.div
      className={`${css.container} ${css[`${language}-bg`]}`}
      initial={{
        x: -100,
        opacity: 0,
      }}
      animate={controls}
    >
      <h3 className={`${css.title} ${css[`title-${language}`]}`}>{title}</h3>
      <div className={css.logoContainer}>
        <div className={css.logo}>
          <img src={logo} alt={`${language}-logo`} />
        </div>
      </div>
      <div
        style={{
          backgroundImage: `url(${background})`,
        }}
        className={css.background}
      />
    </motion.div>
  )
}

export default LanguageCard
