import React, { FC } from 'react'
import css from './git.module.css'
import githubLogo from 'assets/images/github-logo.png'
import github from 'assets/images/github-background.png'
import gitlab from 'assets/images/gitlab-background.png'
import gitlabLogo from 'assets/images/gitlab-logo.png'
import { useAnimationTrigger } from 'hooks'
import { motion } from 'framer-motion'

export type TGitName = 'gitlab' | 'github'

const gitMock: Record<
  TGitName,
  {
    url: string
    link: string
    logo: string
    background: string
    area: string
    color: string
  }
> = {
  github: {
    url: 'github.com/carlosalfonzo',
    link: 'https://github.com/carlosalfonzo',
    logo: githubLogo,
    background: github,
    area: 'card1',
    color: 'white',
  },
  gitlab: {
    url: 'gitlab.com/carlosalfonzo',
    link: 'https://gitlab.com/carlosalfonzo',
    background: gitlab,
    area: 'card2',
    logo: gitlabLogo,
    color: 'black',
  },
}

const GitCard: FC<{
  git: TGitName
  isVisible: boolean
}> = ({ git, isVisible }) => {
  const { area, background, url, logo, color, link } = gitMock[git]
  const controls = useAnimationTrigger(isVisible, {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.4,
      delay: 0.2,
    },
  })
  const logoControl = useAnimationTrigger(isVisible, {
    x: 0,
    y: 0,
    transition: {
      delay: 0.4,
    },
  })

  const openLink = () => {
    window.open(link, '_blank')
  }

  return (
    <motion.div
      onClick={openLink}
      className={`${css.container} ${css[`${git}-gr`]}`}
      style={{
        gridArea: area,
      }}
      initial={{
        opacity: 0,
        y: -100,
      }}
      animate={controls}
      whileHover={{
        cursor: 'pointer',
        scale: 0.9,
      }}
    >
      <div className={css.logoContainer}>
        <img src={logo} alt={git} />
      </div>
      <p
        className={css.url}
        style={{
          color: color,
        }}
      >
        {url}
      </p>
      <motion.div
        initial={{
          y: -100,
          x: 100,
        }}
        animate={logoControl}
        style={{
          backgroundImage: `url(${background})`,
        }}
        className={css.background}
      />
    </motion.div>
  )
}

export default GitCard
