import { LanguageStacks, LanguagesList } from 'components'
import React from 'react'
import css from './skills.module.css'

const SkillsSection = () => {
  return (
    <div className={css.wrapper}>
      <h1 className={css.title}>Skills Set</h1>
      <LanguagesList />
      <LanguageStacks />
    </div>
  )
}

export default SkillsSection
