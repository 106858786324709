import { DegreeCard, PersonalCard, JobCard } from 'components'
import css from './styles.module.css'
import { useAnimationListener } from 'hooks'

const BackgroundSection = () => {
  const [ref, isVisible] = useAnimationListener()

  return (
    <div className={css.wrapper}>
      <PersonalCard isVisible={isVisible} />
      <h1 className={css.title}>Background</h1>
      <DegreeCard degreeType="engineer" isVisible={isVisible} />
      <DegreeCard degreeType="blockchain" isVisible={isVisible} />
      <JobCard jobTitle="galindo" isVisible={isVisible} />
      <JobCard jobTitle="oxoft" isVisible={isVisible} />
      <JobCard jobTitle="keiron" isVisible={isVisible} />
      <JobCard
        isVisible={isVisible}
        jobTitle="glewee"
        style={{
          gridArea: 'glewee',
        }}
      />
      <JobCard
        isVisible={isVisible}
        jobTitle="lenio"
        style={{
          gridArea: 'lenio',
        }}
      />
      <div className={css.visualizer} ref={ref} />
    </div>
  )
}

export default BackgroundSection
