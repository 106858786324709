import { useInView } from 'framer-motion'
import { RefObject, useRef } from 'react'

const useAnimationListener = (): [RefObject<HTMLDivElement>, boolean] => {
  const elementRef = useRef<HTMLDivElement>(null)
  const isVisible = useInView(elementRef)

  return [elementRef, isVisible]
}

export default useAnimationListener
