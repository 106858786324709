import React, { FC } from 'react'
import styles from './menu.module.css'
import { TSectionName } from 'views/Home'
import { motion } from 'framer-motion'

const menuOpts: { label: string; section: TSectionName }[] = [
  {
    label: 'Background',
    section: 'background',
  },
  {
    label: 'Skills Set',
    section: 'skills',
  },
  {
    label: 'Contact',
    section: 'contact',
  },
  {
    label: 'Projects Portfolio',
    section: 'portfolio',
  },
]

const Menu: FC<{
  onClick: (section: TSectionName) => void
}> = ({ onClick = () => {} }) => {
  function goToSection(section: TSectionName) {
    onClick(section)
  }

  return (
    <nav className={`${styles.nav}`}>
      <ol>
        {menuOpts.map(({ label, section }, index) => (
          <motion.li
            key={`${label}-${section}`}
            className={styles.menuItem}
            onClick={() => goToSection(section)}
            initial={{
              y: -30,
              opacity: 0,
            }}
            animate={{
              y: 0,
              opacity: 1,
              transition: {
                duration: 0.3,
                delay: index ? (index + 3) / 10 : 0,
              },
            }}
          >
            {label}
          </motion.li>
        ))}
      </ol>
    </nav>
  )
}

export default Menu
