import React from 'react'
import { HomePage, NotFound } from './views'
import { RouterProvider, createBrowserRouter } from 'react-router-dom'

const appRouting = createBrowserRouter([
  {
    path: '/',
    element: <HomePage />,
    errorElement: <NotFound />,
    index: true,
  },
])

export default function App() {
  return <RouterProvider router={appRouting} />
}
