import React, { useEffect, useMemo, useState } from 'react'
import css from './portfolio.module.css'
import projectMock, { PortfolioCardI } from './portfolio'
import PortfolioCard from 'components/PortfolioCard'
import { Filters } from 'components'
import { FiltersContext, companiesT } from './hooks'
import { useLocation } from 'react-router-dom'

const validCompanies: companiesT[] = ['keiron', 'oxoft', 'glewee', 'personal']

const PortfolioSection = () => {
  const [active, setActive] = useState<companiesT>()
  const { search } = useLocation()

  const filteredMock = useMemo(() => {
    if (active) {
      return projectMock.filter((project) => project.sign === active)
    }
    return projectMock
  }, [active])

  const parsedSearch = useMemo(() => {
    const parse = new URLSearchParams(search)
    return parse
  }, [search])

  useEffect(() => {
    if (
      parsedSearch.has('company') &&
      validCompanies.includes(parsedSearch.get('company') as companiesT)
    ) {
      setActive(parsedSearch.get('company') as companiesT)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parsedSearch])

  return (
    <div className={css.wrapper}>
      <div className={css.filterContainer}>
        <h1 className={css.portfolio}>Portfolio</h1>
        <FiltersContext.Provider
          value={{
            active: active,
            setActive: (nextComp) =>
              nextComp === active ? setActive(undefined) : setActive(nextComp),
          }}
        >
          <Filters />
        </FiltersContext.Provider>
      </div>
      <div className={css.cardsContainer}>
        <div className={css.gridColumn}>
          {filteredMock
            .filter(({ column }: PortfolioCardI) => column === 1)
            .map((card) => (
              <PortfolioCard mode="project" {...card} key={card.title} />
            ))}
        </div>
        <div className={css.gridColumn}>
          {filteredMock
            .filter(({ column }: PortfolioCardI) => column === 2)
            .map((card) => (
              <PortfolioCard mode="project" {...card} key={card.title} />
            ))}
        </div>
        <div className={css.gridColumn}>
          {filteredMock
            .filter(({ column }: PortfolioCardI) => column === 3)
            .map((card) => (
              <PortfolioCard mode="project" {...card} key={card.title} />
            ))}
        </div>
      </div>
    </div>
  )
}

export default PortfolioSection
