import React, { FC } from 'react'
import css from './contact.module.css'
import gmailBg from 'assets/images/gmail-background.png'
import gmailLg from 'assets/images/gmail-logo.png'
import torreBg from 'assets/images/torre-background.png'
import torreLg from 'assets/images/torre-logo.png'
import brealBg from 'assets/images/bereal-background.png'
import brealLg from 'assets/images/bereal-logo.png'
import igBg from 'assets/images/instagram-background.png'
import igLg from 'assets/images/instagram-logo.png'
import { useAnimationTrigger } from 'hooks'
import { motion } from 'framer-motion'

export type TNetwork = 'gmail' | 'torre' | 'breal' | 'ig'

const NetworkMock: Record<
  TNetwork,
  {
    logo: string
    background: string
    urls: string[]
    logoAlignment: 'flex-start' | 'flex-end'
    logoSize?: number
    urlPad?: number
    link?: string
  }
> = {
  gmail: {
    logo: gmailLg,
    background: gmailBg,
    urls: ['ingalfonzocarlos@gmail.com', 'info@carlosalfonzo.tech'],
    urlPad: 0,
    logoAlignment: 'flex-end',
  },
  torre: {
    logo: torreLg,
    background: torreBg,
    urls: ['torre.co/carlosalfonzo098'],
    link: 'https://torre.co/carlosalfonzo098',
    logoAlignment: 'flex-end',
  },
  breal: {
    logo: brealLg,
    background: brealBg,
    urls: ['alfonzo21'],
    logoAlignment: 'flex-start',
    logoSize: 170,
  },
  ig: {
    logo: igLg,
    background: igBg,
    urls: ['alfonzo.cj'],
    link: 'https://instagram.com/alfonzo.cj',
    logoAlignment: 'flex-start',
    logoSize: 60,
  },
}

const ContactCard: FC<{
  network: TNetwork
  isVisible: boolean
}> = ({ network, isVisible }) => {
  const { background, logo, logoAlignment, urls, urlPad, logoSize, link } = NetworkMock[network]
  const controls = useAnimationTrigger(isVisible, {
    opacity: 1,
    transition: {
      duration: 1,
      delay: 0.4,
    },
  })
  const logoControl = useAnimationTrigger(isVisible, {
    x: 0,
    transition: {
      delay: 0.5,
    },
  })

  const openLink = () => {
    if (link) window.open(link, '_blank')
  }

  return (
    <motion.div
      className={`${css.cardContainer} ${css[`${network}-bg`]}`}
      initial={{
        opacity: 0,
      }}
      animate={controls}
      whileHover={
        link
          ? {
              cursor: 'pointer',
              scale: 0.9,
            }
          : {}
      }
      onClick={openLink}
    >
      <div className={css.content}>
        <div
          className={css.logoContainer}
          style={{
            alignSelf: logoAlignment,
            width: logoSize || undefined,
          }}
        >
          <img alt={`${network}-card-logo`} src={logo} />
        </div>
        <div
          className={css.urlsContainer}
          style={{
            paddingTop: urlPad || undefined,
          }}
        >
          {urls.map((url) => (
            <p key={url}>{url}</p>
          ))}
        </div>
      </div>
      <motion.div
        className={css.background}
        initial={{
          x: 100,
        }}
        animate={logoControl}
        style={{
          backgroundImage: `url(${background})`,
        }}
      />
    </motion.div>
  )
}

export default ContactCard
