import React, { FC } from 'react'
import css from './banner.module.css'
import { TStackName } from '../StackEndList'
import front from 'assets/images/frontend-logos.png'
import back from 'assets/images/backend-logos.png'
import soft from 'assets/images/soft-logos.png'
import { motion } from 'framer-motion'
import { useAnimationTrigger } from 'hooks'

const stackImg: Record<TStackName, string> = {
  front: front,
  back: back,
  soft: soft,
}

const StackBanner: FC<{
  stack: TStackName
  isVisible: boolean
}> = ({ stack, isVisible }) => {
  const banner = stackImg[stack]
  const controls = useAnimationTrigger(isVisible, {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.3,
      delay: 0.2,
    },
  })

  return (
    <motion.div
      id={stack}
      className={`${css.bannerContainer} ${css[`${stack}-gr`]}`}
      initial={{
        y: -100,
        opacity: 0,
      }}
      animate={controls}
    >
      <motion.div
        className={css.imageContainer}
        initial={{
          opacity: 0,
          scale: 1.5,
        }}
        animate={{
          opacity: 1,
          scale: 1,
          transition: {
            duration: 1,
            delay: 0.5,
          },
        }}
      >
        <img src={banner} alt={`${stack}-banners`} />
      </motion.div>
    </motion.div>
  )
}

export default StackBanner
