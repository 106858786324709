import { AnimationControls, AnimationDefinition, useAnimation } from 'framer-motion'
import { useEffect } from 'react'

const useAnimationTrigger = (
  isVisible: boolean,
  variantOrObj: AnimationDefinition,
): AnimationControls => {
  const controls = useAnimation()

  useEffect(() => {
    if (isVisible) controls.start(variantOrObj)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible])

  return controls
}

export default useAnimationTrigger
