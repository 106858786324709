import articlebanner1 from 'assets/images/article-1-banner.png'

export interface ArticleCardI {
  title: string
  link: string
  description: string[]
  image: string
  column: 1 | 2 | 3
}

export const articlesMock: ArticleCardI[] = [
  {
    title: 'Lo impresindible de ser presindible',
    link: 'https://community.listopro.com/lo-imprescindible-de-ser-prescindible/',
    description: [
      'in this article, I expose the culture of having a hero developer and the reasons why this is not actually a good idea or either a good practice for software developers and finally, some recommendations to follow in order to prevent it and/or start to solve it.',
    ],
    column: 1,
    image: articlebanner1,
  },
]

export default articlesMock
