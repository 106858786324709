import React, { FC } from 'react'
import css from './welcome.module.css'
import Menu from './Menu'
import logo from 'assets/images/carlos-full-logo.png'
import backgroundImg from 'assets/images/main-image-code.png'
import { TSectionName } from 'views/Home'
import { motion } from 'framer-motion'

const WelcomeBanner: FC<{
  onClick: (sectionName: TSectionName) => void
}> = ({ onClick }) => {
  return (
    <div
      className={`${css.wrapper}`}
      style={{
        backgroundImage: `url(${backgroundImg})`,
      }}
    >
      <section className={`${css.container}`}>
        <div className={`${css.top}`}>
          <motion.span
            className={`${css.logoContainer}`}
            initial={{
              opacity: 0,
              y: -100,
            }}
            animate={{
              opacity: 1,
              y: 0,
              transition: {
                duration: 0.4,
              },
            }}
          >
            <img src={logo} alt="carlos alfonzo logo" />
          </motion.span>
        </div>
        <div className={css.messageCont}>
          <div className={css.message}>
            <motion.h3
              className={css.word}
              initial={{
                x: -100,
              }}
              animate={{
                x: 0,
              }}
            >
              My Professional Portfolio
            </motion.h3>
          </div>
          <Menu onClick={onClick} />
        </div>
      </section>
    </div>
  )
}

export default WelcomeBanner
