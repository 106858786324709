import React, { FC } from 'react'
import css from './portfoliocard.module.css'
import linkIcon from 'assets/images/link-icon.png'
import blueUserIcon from 'assets/images/user-blue-icon.png'
import whiteUserIcon from 'assets/images/user-white-icon.png'
import gleweeLg from 'assets/images/glewee-logo.png'
import carlosInit from 'assets/images/carlos-logo-initials-black.png'
import keironLg from 'assets/images/keiron-logo.png'
import oxoftLg from 'assets/images/oxoft-logo.png'
import { motion } from 'framer-motion'
import { useAnimationListener, useAnimationTrigger } from 'hooks'
import { companiesT } from 'views/Home/sections/PortfolioSection/hooks'

//@ts-expect-error
const companyLog: Record<companiesT, { logo: string; link: string }> = {
  glewee: { logo: gleweeLg, link: 'https://glewee.com' },
  keiron: { logo: keironLg, link: 'https://www.keiron.cl/' },
  oxoft: { logo: oxoftLg, link: 'https://oxoft.io' },
}

const useLogo = (companyName?: companiesT) => {
  if (companyName && companyName !== 'personal') {
    return companyLog[companyName]
  }
  return { logo: carlosInit, link: undefined }
}

interface ProjectCardI {
  title: string
  link?: string
  description: string[]
  sign?: companiesT
  charge?:
    | 'One Man Work'
    | 'LEAD DEVELOPER'
    | 'FULL STACK DEVELOPER'
    | 'Email Developer'
    | 'Software Engineer'
  image: string
  status?: 'Side project' | 'Finished' | 'Canceled development' | ''
  productionStatus?:
    | 'Live Platform'
    | 'in development'
    | 'partially in development'
    | 'Production live'
    | 'No live'
  column: 1 | 2 | 3
  mode: 'article' | 'project'
}

const PortfolioCard: FC<ProjectCardI> = ({
  image,
  title,
  productionStatus,
  status,
  description,
  sign,
  charge,
  link,
  mode,
}) => {
  const { logo, link: companyLink } = useLogo(sign)
  const [ref, isVisible] = useAnimationListener()
  const controls = useAnimationTrigger(isVisible, {
    opacity: 1,
    scale: 1,
    rotateY: '0deg',
    rotateX: '0deg',
    transition: {
      duration: 0.8,
      delay: 0.2,
    },
  })

  const openLink = () => {
    if (link) {
      window.open(link, '_tab')
    }
  }

  const openCompanyLink = () => {
    if (companyLink) window.open(companyLink, '_tab')
  }

  return (
    <motion.div
      className={css.cardContainer}
      initial={{
        opacity: 0,
        scale: 0.8,
        rotateY: '90deg',
        rotateX: '45deg',
      }}
      animate={controls}
    >
      <div className={css.bannerContainer}>
        <img alt={`${title}-description`} src={image} />
      </div>
      <div className={css.cardContent}>
        <div className={css.statusCont}>
          <p>{status}</p>
          {status && productionStatus && <p>-</p>}
          <p>{productionStatus}</p>
        </div>
        <h6>{title}</h6>
        <div className={css.descriptionCont}>
          {description.map((paragraph) => (
            <p>{paragraph}</p>
          ))}
        </div>
        <div className={css.buttonsCont}>
          <div
            className={`${css.linkCont} ${link ? css.animate : css.noAnimate} ${
              mode === 'article' ? css.centerSelf : undefined
            }`}
            onClick={openLink}
          >
            <div className={css.iconCont}>
              <img alt="link to project" src={linkIcon} />
            </div>
          </div>
          {mode === 'project' && (
            <>
              <div className={`${css.companyCont} ${css[`${sign!}-bg`]}`} onClick={openCompanyLink}>
                <div className={css.logoCont}>
                  <img alt={`${sign}-logo`} src={logo} />
                </div>
              </div>
              <div
                className={`${css.roleCont} ${
                  charge === 'FULL STACK DEVELOPER' ? css.altBg : css.regularBg
                }`}
              >
                <div className={css.contentCont}>
                  <div className={css.roleAndIcon}>
                    <img
                      alt="user-icon"
                      src={charge !== 'FULL STACK DEVELOPER' ? blueUserIcon : whiteUserIcon}
                    />
                    <p>{charge}</p>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      <span className={css.visualizator} ref={ref} />
    </motion.div>
  )
}

export default PortfolioCard
