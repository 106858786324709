import React, { FC, useMemo } from 'react'
import css from './styles.module.css'
import logo from 'assets/images/carlos-full-logo.png'
import picture from 'assets/images/personal-header-picture.png'
import flag from 'assets/images/venezuela-flag.png'
import { motion } from 'framer-motion'
import { useAnimationTrigger } from 'hooks'

const PersonalCard: FC<{
  isVisible: boolean
}> = ({ isVisible }) => {
  const controls = useAnimationTrigger(isVisible, {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.5,
      delay: 0.5,
    },
  })
  const yearsSince = useMemo(() => {
    const origin = new Date('01-03-2017')
    const today = new Date()
    return today.getFullYear() - origin.getFullYear()
  }, [])

  return (
    <motion.div
      className={css.container}
      initial={{
        opacity: 0,
        y: -200,
      }}
      animate={controls}
    >
      <div className={css.imageContainer}>
        <img src={picture} alt="carlos alfonzo" />
      </div>
      <div className={css.contentContainer}>
        <div className={css.logoContainer}>
          <img src={logo} alt="carlos alfonzo full logo" />
        </div>
        <div className={css.overviewContainer}>
          <div className={css.flagContainer}>
            <img src={flag} alt="venezuelan flag" />
          </div>
          <div className={css.yearsContainer}>
            <h5>{yearsSince}</h5>
            <p>years of experience</p>
          </div>
          <div className={css.englishContainer}>
            <h5>C2</h5>
            <p>English</p>
          </div>
        </div>
      </div>
    </motion.div>
  )
}

export default PersonalCard
