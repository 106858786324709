import React from 'react'
import css from './stacks.module.css'
import StackEndList from './components/StackEndList'
import StackBanner from './components/StackBanner'
import { useAnimationListener } from 'hooks'

const LanguageStacks = () => {
  const [ref, isVisible] = useAnimationListener()
  return (
    <div className={css.gridContainer}>
      <StackEndList stack="front" isVisible={isVisible} />
      <StackBanner stack="front" isVisible={isVisible} />
      <StackBanner stack="back" isVisible={isVisible} />
      <StackEndList stack="back" isVisible={isVisible} />
      <StackEndList stack="soft" isVisible={isVisible} />
      <StackBanner stack="soft" isVisible={isVisible} />
      <div className={css.visualizer} ref={ref} />
    </div>
  )
}

export default LanguageStacks
