import React from 'react'
import css from './footer.module.css'
import landscape from 'assets/images/landscape.png'

export default function Footer() {
  return (
    <footer
      className={`${css.wrapper} flex`}
      style={{
        backgroundImage: `url(${landscape})`,
      }}
    >
      <div className={`${css.container} flex wrap`}>
        <div className={css.powered}>
          <p className={css.copyrigth}>UI Design & Development by</p>
        </div>
        <div className={css.logoCont}>
          <p className={css.logo}>&lt;/Carlos Alfonzo&gt;</p>
          <p className={css.s}>'s</p>
        </div>
        <p className={css.joke}>Brain & Hands</p>
        <p className={css.copyrigth}>COPYRIGHT © {new Date().getFullYear()} </p>
      </div>
    </footer>
  )
}
