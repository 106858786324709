import React from 'react'
import css from './filters.module.css'
import carlosInit from 'assets/images/carlos-logo-initials-black.png'
import oxoftLg from 'assets/images/oxoft-logo.png'
import gleweeLg from 'assets/images/glewee-logo.png'
import keironLg from 'assets/images/keiron-logo.png'
import { useFiltersContext } from 'views/Home/sections/PortfolioSection/hooks'

const Filters = () => {
  const { active, setActive } = useFiltersContext()
  return (
    <div className={css.container}>
      <div
        onClick={() => setActive('keiron')}
        className={`${css.companyCircle} ${css.keiron} ${
          active === 'keiron' ? css.active : css.hoverable
        }`}
      >
        <img src={keironLg} alt="keiron company" />
      </div>
      <div
        onClick={() => setActive('oxoft')}
        className={`${css.companyCircle} ${css.oxoft} ${
          active === 'oxoft' ? css.active : css.hoverable
        }`}
      >
        <img src={oxoftLg} alt="oxoft company" />
      </div>
      <div
        onClick={() => setActive('glewee')}
        className={`${css.companyCircle} ${css.glewee} ${
          active === 'glewee' ? css.active : css.hoverable
        }`}
      >
        <img src={gleweeLg} alt="glewee company" />
      </div>
      <div
        onClick={() => setActive('personal')}
        className={`${css.companyCircle} ${css.personal} ${
          active === 'personal' ? css.active : css.hoverable
        }`}
      >
        <img src={carlosInit} alt="personal company" />
      </div>
    </div>
  )
}

export default Filters
