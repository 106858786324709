import { createContext, useContext } from 'react'

export type companiesT = 'keiron' | 'oxoft' | 'glewee' | 'personal'

export type filtersContextT = {
  active?: companiesT
  setActive: (nextCom: companiesT) => void
}

export const FiltersContext = createContext<filtersContextT>({
  active: undefined,
  setActive: () => {},
})

export const useFiltersContext = () => useContext(FiltersContext)
