import React, { useEffect, useMemo, useRef } from 'react'
import BackgroundSection from './sections/background'
import SkillsSection from './sections/SkillsSection'
import ContactSection from './sections/ContactSection'
import css from './styles.module.css'
import PortfolioSection from './sections/PortfolioSection'
import WelcomeBanner from './sections/WelcomeBanner'
import Footer from './sections/Footer'
import ArticlesSection from './sections/ArticlesSection'
import { useLocation, useNavigate } from 'react-router-dom'

export type TSectionName = 'background' | 'skills' | 'contact' | 'portfolio'

const validSectionNames: TSectionName[] = ['background', 'skills', 'contact', 'portfolio']

const HomePage = () => {
  const backgroundRef = useRef<HTMLElement>(null)
  const skillsRef = useRef<HTMLElement>(null)
  const contactRef = useRef<HTMLElement>(null)
  const portfolioRef = useRef<HTMLElement>(null)
  const { search } = useLocation()
  const nav = useNavigate()

  const parsedSearch = useMemo(() => {
    const parse = new URLSearchParams(search)
    return parse
  }, [search])

  const scrollTo = (sectionName: TSectionName) => {
    const sectionsRefs: Record<TSectionName, HTMLElement | null> = {
      background: backgroundRef.current,
      skills: skillsRef.current,
      contact: contactRef.current,
      portfolio: portfolioRef.current,
    }
    const target = sectionsRefs[sectionName] as HTMLElement
    target?.scrollIntoView()
  }

  useEffect(() => {
    if (parsedSearch.has('section')) {
      if (validSectionNames.includes(parsedSearch.get('section') as TSectionName)) {
        scrollTo(parsedSearch.get('section') as TSectionName)
      } else {
        nav('/', {
          replace: true,
        })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parsedSearch])

  return (
    <div>
      <WelcomeBanner onClick={scrollTo} />
      <main className={css.wrapper}>
        <section className={css.container} ref={backgroundRef}>
          <BackgroundSection />
        </section>
        <section className={css.container} ref={skillsRef}>
          <SkillsSection />
        </section>
        <section className={css.container} ref={contactRef}>
          <ContactSection />
        </section>
        <section className={css.noMargin} ref={portfolioRef}>
          <PortfolioSection />
        </section>
        <section className={css.container}>
          <ArticlesSection />
        </section>
      </main>
      <Footer />
    </div>
  )
}

export default HomePage
