import React from 'react'
import css from './contact.module.css'
import { ContactCard, GitCard } from 'components'
import logoInitials from 'assets/images/carlos-logo-initials.png'
import blurredBg from 'assets/images/blurred-background.png'
import linkedinLogo from 'assets/images/linkedin-logo.png'
import linkedinBg from 'assets/images/linkedin-background.png'
import { useAnimationListener, useAnimationTrigger } from 'hooks'
import { motion } from 'framer-motion'

const ContactSection = () => {
  const [ref, isVisible] = useAnimationListener()
  const linkedInAnimation = useAnimationTrigger(isVisible, {
    y: 0,
    opacity: 1,
    transition: {
      duration: 0.5,
      delay: 0.2,
    },
  })
  const linkedILogoAn = useAnimationTrigger(isVisible, {
    scale: 1,
    transition: {
      delay: 0.4,
      duration: 1,
    },
  })
  const bioAnimation = useAnimationTrigger(isVisible, {
    scale: 1,
    opacity: 1,
    transition: {
      duration: 0.5,
      delay: 0.2,
    },
  })

  return (
    <div className={css.contactAndPortfolioContainer}>
      <GitCard git="gitlab" isVisible={isVisible} />
      <GitCard git="github" isVisible={isVisible} />
      <h1 className={css.title}>Contact</h1>
      <motion.div
        className={css.bioContainer}
        initial={{
          scale: 0.9,
          opacity: 0,
        }}
        animate={bioAnimation}
      >
        <div className={css.content}>
          <p>
            If you're convinced I'm an exciting developer, and you indeed wish to get in touch with
            me, I left you down here on all my social communications.
          </p>
          <p>
            Feel free to text me wherever you feel more comfortable, and I'll reply as soon as
            possible.
          </p>
          <p>Thank you so much for making it down here!</p>
        </div>
        <div className={css.logoContainer}>
          <img alt="carlos alfonzo logo" src={logoInitials} />
        </div>
        <div
          style={{
            backgroundImage: `url(${blurredBg})`,
          }}
          className={css.bioBackground}
        />
      </motion.div>
      <motion.div
        className={css.linkedinContainer}
        initial={{
          y: -100,
          opacity: 0,
        }}
        animate={linkedInAnimation}
        whileHover={{
          cursor: 'pointer',
          scale: 0.95,
        }}
        onClick={() => window.open('https://linkedin.com/in/carlos-alfonzo', '_blank')}
      >
        <div className={css.content}>
          <div className={css.logoContainer}>
            <img alt="linkedin" src={linkedinLogo} />
          </div>
          <h3 className={css.url}>linkedin.com/in/carlos-alfonzo</h3>
        </div>
        <motion.div
          initial={{
            scale: 2,
          }}
          animate={linkedILogoAn}
          className={css.background}
          style={{
            backgroundImage: `url(${linkedinBg})`,
            transformOrigin: '100 100',
          }}
        />
      </motion.div>
      <ContactCard network="gmail" isVisible={isVisible} />
      <ContactCard network="torre" isVisible={isVisible} />
      <ContactCard network="breal" isVisible={isVisible} />
      <ContactCard network="ig" isVisible={isVisible} />
      <div className={css.visualizer} ref={ref} />
    </div>
  )
}

export default ContactSection
