import LanguageCard from 'components/LanguageCard'
import React from 'react'
import css from './languages.module.css'
import { useAnimationListener } from 'hooks'

const LanguagesList = () => {
  const [ref, isVisible] = useAnimationListener()

  return (
    <div className={css.container}>
      <h3 className={css.title}>Programming Languages</h3>
      <div className={css.cardsContainer}>
        <LanguageCard isVisible={isVisible} language="js" />
        <LanguageCard isVisible={isVisible} language="ts" />
        <LanguageCard isVisible={isVisible} language="py" />
        <LanguageCard isVisible={isVisible} language="php" />
        <LanguageCard isVisible={isVisible} language="sql" />
        <LanguageCard isVisible={isVisible} language="sol" />
      </div>
      <div className={css.visualizer} ref={ref} />
    </div>
  )
}

export default LanguagesList
